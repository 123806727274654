import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { readQueryParameters } from '../shared-functions';
import { Country, ICompany, IEnterprise } from '../models/company.model';
import { TranslateService } from '@ngx-translate/core';
import { IApplication } from '../models/application.model';
import { Application } from '../classes/application.class';
import { Pagination } from '../models/pagination.interface';
import { Enterprise, Company, Organization, AllJobsOrganization, ALL_JOBS_DOMAIN } from '../classes/organization.class';

@Injectable({
  providedIn: 'root',
})
export class SetupService {

  protected _domain: string;
  protected _isEnterprise = false;

  constructor(
    private http: HttpClient,
    protected translateService: TranslateService
  ) {}

  getOrganizationInfo(): Observable<Organization> {
    this.setDomain();

    if (this._domain === ALL_JOBS_DOMAIN) {
      let params = new HttpParams();
      params = params.set('limit', 1000);
      return this.http.get(`${environment.countries}`, { params })
        .pipe(
          map(({data}: Pagination<Country>) => {
            return new AllJobsOrganization(data);
          })
        );
    }

    if (this._isEnterprise) {
      let params = new HttpParams();
      params = params.set('enterpriseDomain', this._domain);

      return this.http.get(`${environment.enterprises}`, { params })
        .pipe(
          map(({data}: Pagination<IEnterprise>) => {
            return data[0] ? new Enterprise(data[0]) : null;
          })
        );
    }

    return this.http.get(`${environment.companyInfo}/${this._domain}`)
      .pipe(
        map(({data}: Pagination<ICompany>) => {
          return data[0] ? new Company(data[0]) : null;
        })
      );
  }

  setDomain(): void {
    const { host, hostname } = window.location;

    if (hostname === 'localhost' || hostname.includes('192.168.') || hostname.includes('bs-local')) {
      this._domain = 'konacna';
      // this._domain = ALL_JOBS_DOMAIN;
      // this._domain = 'final';
      // this._isEnterprise = true;
      return;
    }

    const externalDomain = environment.externalDomains
      .find(domainObject => host.includes(domainObject.externalDomain));

    if (externalDomain) {
      this._domain = externalDomain.companyDomain;
      return;
    }

    const domain: string[] = hostname.split('.');

    if (domain[0] === 'www') {
      domain.shift();
    }

    if (domain[1] === 'global') {
      this._isEnterprise = true;
    }

    const higherIndex = domain.findIndex((domainItem) => domainItem === 'higher' || domainItem === 'uhigher');
    if (higherIndex !== 1 && higherIndex === 2 && !this._isEnterprise) {
      window.location.href = 'https://www.higher.nu';
    }

    this._domain = domain[0];
  }

  getApplicationInfo(): Observable<Application> {
    const guid = readQueryParameters('application');

    return this.http.get(`${environment.applicationsPage}/${guid}`)
      .pipe(
        map(({data}: Pagination<IApplication>) => new Application(data[0]))
      );
  }

  getBaseApplicationInfo(guid: string): Observable<IApplication> {
    return this.http.get<Pagination<IApplication>>(`${environment.applicationsPage}/${guid}`)
      .pipe(
        map(({ data }: Pagination<IApplication>) => data[0])
      );
  }
}
